<template>
    <v-row>
        <v-col align="center" cols="12">
            <v-card max-width="800px" dark class="px-5 py-2" flat>
                <v-row no-gutters>
                    <v-col cols="9">
                        <v-select
                            :loading="loading"
                            :disabled="loading"
                            label="Studios"
                            :items="availableStudios"
                            v-model="selectedStudios"
                            multiple
                        ></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-select
                            @change="fetchTimestops()"
                            class="pl-5"
                            label="Status"
                            :items="availableStates"
                            v-model="selectedStatus"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>

        <v-col align="center" cols="12" v-if="userService.hasRight('d_v_timestops')">
            <v-btn class="mx-2" @click="createTimestopAsCustomer = true">Timestop erstellen Kundenansicht</v-btn>
            <v-btn class="mx-2" @click="showPaymentsForCustomer = true">Zahlungen Kundenansicht</v-btn>
        </v-col>
        <v-col align="center" cols="12" v-if="loading">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </v-col>
        <v-col align="center" cols="12" v-if="timestopsFiltered.length === 0">
            <v-alert max-width="800" type="info">Keine Anfragen gefunden</v-alert>
        </v-col>
        <v-col align="center" cols="12">
            <v-expansion-panels style="max-width: 800px" dark>
                <v-expansion-panel v-for="timestop of timestopsFiltered" :key="timestop._id">
                    <v-expansion-panel-header>
                        <v-card-title class="py-0">
                            <v-chip class="mr-2">
                                {{ timestop.studio.name + ' ' + $moment(timestop.createdAt).format('DD.MM.YYYY') }}
                            </v-chip>
                            {{ timestop.customerInfos.firstName + ' ' + timestop.customerInfos.lastName }}
                        </v-card-title>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <v-card class="rounded-lg" align="left" max-width="700px" dark flat>
                            <v-card-subtitle class="pa-0">
                                {{ timestop.customerInfos.email }}
                                <br />
                                {{ timestop.customerInfos.birthday }}
                                <br />
                                {{ timestop.customerInfos.street }}
                                <br />
                                {{ timestop.customerInfos.city + ' ' + timestop.customerInfos.zip }}
                                <br />
                            </v-card-subtitle>
                            <v-card-title class="h5 pl-0">Zeitpunkt</v-card-title>
                            Zeitstpanne:
                            {{ timestop.tacData.TimeSpan.Start + ' - ' + timestop.tacData.TimeSpan.End }}
                            <br />
                            Dauer: {{ timestop.tacData.Duration + ' Tage' }}

                            <v-card-title class="h5 pl-0">Grund</v-card-title>
                            <v-row>
                                <v-col
                                    v-for="(galleryItem, index) of timestop.timestopInfos.gallery"
                                    :cols="4"
                                    :key="galleryItem.src"
                                >
                                    <template v-if="galleryItem.src.endsWith('.pdf')">
                                        <!-- PDF Vorschau oder Link -->
                                        <v-card @click="openPdf(galleryItem.dataUrl)">
                                            <v-card-title class="justify-center">Anhang {{ index + 1 }}</v-card-title>
                                            <v-card-subtitle class="text-center">PDF anzeigen</v-card-subtitle>
                                        </v-card>
                                    </template>
                                    <template v-else>
                                        <!-- Bild anzeigen -->
                                        <v-img
                                            @click="fullSizeImage = galleryItem.dataUrl"
                                            height="100px"
                                            contain
                                            :src="galleryItem.dataUrl"
                                        ></v-img>
                                    </template>
                                </v-col>
                            </v-row>

                            <v-textarea label="Kommentar" disabled v-model="timestop.tacData.Comment"></v-textarea>
                            <v-select
                                mandatory
                                :items="
                                    tacReasons.Reasons.Reason.map((reason) => {
                                        return { text: reason.ReasonCode, value: reason.ReasonID.UniqueID }
                                    })
                                "
                                v-model="timestop.tacData.ReasonId"
                                label="Grund des Unterbruchs"
                                required
                            ></v-select>
                            <div style="background: white; width: 200px; height: 100px">
                                <v-img
                                    class="pt-1"
                                    width="200px"
                                    height="100px"
                                    contain
                                    :src="timestop.customerInfos.signature"
                                ></v-img>
                            </div>
                            <v-row
                                class="mt-2"
                                v-if="
                                    timestop.status !== 'payed' &&
                                    timestop.status !== 'accepted' &&
                                    timestop.status !== 'onHold'
                                "
                            >
                                <v-col
                                    cols="4"
                                    class="text-center"
                                    @click="
                                        () => {
                                            timestop.withAttest = true
                                            timestop.officeChangedFee = false
                                        }
                                    "
                                >
                                    <div
                                        style="border: 3px dashed"
                                        class="pa-2"
                                        :style="
                                            timestop.withAttest && !timestop.officeChangedFee
                                                ? 'border-color: green'
                                                : ''
                                        "
                                    >
                                        <span class="font-weight-bold">Mit Attest</span>
                                        <br />
                                        <span>{{ timestop.fee.withAttest + 'CHF' }}</span>
                                        <br />
                                        <br />

                                        <v-btn icon>
                                            <v-icon :color="timestop.withAttest ? 'primary' : ''">
                                                mdi-check-circle-outline
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col
                                    cols="4"
                                    class="text-center"
                                    @click="
                                        () => {
                                            timestop.withAttest = false
                                            timestop.officeChangedFee = false
                                        }
                                    "
                                >
                                    <div
                                        style="border: 3px dashed"
                                        :style="
                                            !timestop.withAttest && !timestop.officeChangedFee
                                                ? 'border-color: green'
                                                : ''
                                        "
                                        class="pa-2"
                                    >
                                        <span class="font-weight-bold">Ohne Attest</span>
                                        <br />
                                        <span>{{ timestop.fee.withoutAttest + 'CHF' }}</span>
                                        <br />
                                        <br />
                                        <v-btn icon>
                                            <v-icon
                                                :color="
                                                    timestop.withAttest || timestop.officeChangedFee ? '' : 'primary'
                                                "
                                            >
                                                mdi-check-circle-outline
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col
                                    cols="4"
                                    class="text-center"
                                    @click="
                                        () => {
                                            timestop.withAttest = false
                                            timestop.officeChangedFee = true
                                        }
                                    "
                                >
                                    <div
                                        style="border: 3px dashed"
                                        :style="timestop.officeChangedFee ? 'border-color: green' : ''"
                                        class="pa-2"
                                    >
                                        <span class="font-weight-bold">Neue Gebühr</span>
                                        <v-text-field
                                            style="text-align-last: center"
                                            v-model="timestop.officeChangedFeeAmount"
                                            dark
                                            type="Number"
                                            suffix="CHF"
                                            label="Gebühr"
                                            class="pa-0 ma-0 elevation-0"
                                            solo
                                            flat
                                            hide-details
                                        ></v-text-field>
                                        <v-btn icon>
                                            <v-icon :color="timestop.officeChangedFee ? 'primary' : ''">
                                                mdi-check-circle-outline
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <v-select
                                        v-model="preSelectableRejections"
                                        label="Ablehnungsgrund"
                                        @change="changeRejectionValue($event, timestop)"
                                        :items="rejectionItems"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        label="Rückmeldung an Kunden"
                                        v-model="timestop.officeNote"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row v-else class="mt-2">
                                <v-card-title v-if="selectedStatus === 'onHold'">
                                    Offene Zahlung: {{ timestop.tacData.IdlePeriodFee.value + ' CHF' }}
                                    <v-btn color="primary" @click="saveToTAC(timestop, 'payed')">
                                        Als bezahlt markieren
                                    </v-btn>
                                </v-card-title>
                                <v-col cols="12" v-if="timestop.officeNote">
                                    <v-textarea
                                        label="Rückmeldung an Kunden"
                                        v-model="timestop.officeNote"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row v-if="timestop.tacPayResponse">
                                <v-card-title>
                                    TAC RECHNUNG (Nr. {{ timestop.tacPayResponse.InvoiceNumber }}):
                                </v-card-title>
                                <v-card @click="openPdf(timestop.tacPayResponse.ReceiptImage.MediaData, true)">
                                    <v-card-title class="justify-center">Rechnung anzeigen</v-card-title>
                                </v-card>
                            </v-row>
                            <v-card-actions
                                v-if="selectedStatus === 'open' || selectedStatus === 'payed'"
                                class="justify-content-center"
                            >
                                <v-btn color="red" @click="saveToTAC(timestop, 'rejected')">Ablehnen</v-btn>
                                <v-btn
                                    v-if="false"
                                    color="orange"
                                    :disabled="!isAcceptButtonEnabled(timestop)"
                                    @click="saveToTAC(timestop, 'onHold')"
                                >
                                    Zurück an Kunden
                                </v-btn>
                                <!-- 

                                    :disabled="isAcceptButtonEnabled(timestop)"
                                -->
                                <v-btn
                                    color="primary"
                                    @click="
                                        timestop.status === 'open'
                                            ? saveToTAC(timestop, 'onHold')
                                            : saveToTAC(timestop, 'accepted')
                                    "
                                >
                                    {{
                                        timestop.status === 'open'
                                            ? 'Zahlungsaufforderung senden'
                                            : 'Abschliessen und in TAC übertragen'
                                    }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-dialog max-width="600px" :value="fullSizeImage" persistent @click:outside="fullSizeImage = null">
                <v-card>
                    <v-img contain :src="fullSizeImage"></v-img>
                </v-card>
            </v-dialog>
            <v-dialog max-width="500px" v-model="showPaymentsForCustomer">
                <timestop-view-user-payments></timestop-view-user-payments>
            </v-dialog>
            <v-dialog max-width="500px" style="background: white" v-model="createTimestopAsCustomer">
                <timestop-view-user-create-timestop
                    v-if="createTimestopAsCustomer"
                ></timestop-view-user-create-timestop>
            </v-dialog>
        </v-col>
    </v-row>
</template>
<script>
import api from '@/api'
import timestopViewUserPayments from './timestopViewUserPayments'
import timestopViewUserCreateTimestop from './timestopviewUserCreateTimestop'
import userService from '../../../services/userService'

export default {
    components: {
        timestopViewUserPayments,
        timestopViewUserCreateTimestop,
    },
    data() {
        return {
            preSelectableRejections: null,
            userService,
            createTimestopAsCustomer: false,
            showPaymentsForCustomer: false,
            tacReasons: null,
            loading: true,
            selectedStudios: [],
            timestops: [],
            fullSizeImage: null,
            selectedStatus: 'open',
            availableStates: [
                {
                    text: 'Offen',
                    value: 'open',
                },
                {
                    text: 'Abgelehnt',
                    value: 'rejected',
                },
                {
                    text: 'Abgeschlossen',
                    value: 'accepted',
                },
                {
                    text: 'Zahlungsaufforderung',
                    value: 'onHold',
                },
                {
                    text: 'Bezahlt / Nicht eingetragen',
                    value: 'payed',
                },
                {
                    text: 'Durch Kunden abgelehnt',
                    value: 'rejectedCustomer',
                },
            ],
            rejectionItems: [
                {
                    text: 'Mehr als zwei Timestops',
                    value: 0,
                    rejectionText: 'Du hast bereits zwei Timestops während der Laufzeit deines Abonnements eingelöst.',
                },
                {
                    text: 'Länger als 6 Monate',
                    value: 1,
                    rejectionText: 'Du hattest bereits einen Timestop über eine Laufzeit von 6 Monaten.',
                },
                {
                    text: 'Laufzeit des Attests',
                    value: 2,
                    rejectionText: 'Die Zeitdauer des Attests entspricht nicht der von dir gewählten Zeitdauer.',
                },
                {
                    text: 'Falsches Dokument',
                    value: 3,
                    rejectionText: 'Leider entspricht das beigefügte Dokument nicht dem Grund für den Timestop.',
                },
            ],
            timestopStateMap: {
                open: 'Offen',
                rejected: 'Abgelehnt',
                onHold: 'Zahlungsaufforderung',
                payed: 'Bezahlt / Nicht eingetragen',
                accepted: 'Abgeschlossen',
                rejectedCustomer: 'Durch Kunden abgelehnt',
            },
        }
    },
    created() {
        this.fetchTimestops()
        if (!this.userService.hasRight('d_v_timestops')) {
            this.selectedStatus = 'onHold'
            this.availableStates = [
                {
                    text: 'Zahlungsaufforderung',
                    value: 'onHold',
                },
            ]
        }
    },
    methods: {
        openPdf(url, addPrefix = false) {
            if (addPrefix) url = 'data:application/pdf;base64' + url
            const link = document.createElement('a')
            link.href = url
            console.log(url)
            link.download = 'dokument.pdf' // Der Name der heruntergeladenen Datei

            // Das "a"-Element zum Dokument hinzufügen, den Klick auslösen und das Element entfernen
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        },
        changeRejectionValue(val, timestop) {
            timestop.officeNote = this.rejectionItems[val].rejectionText
            console.log(val, timestop)
        },
        isAcceptButtonEnabled(timestop) {
            // Initial Fee has changed

            if (timestop.officeChangedFee && timestop.officeChangedFeeAmount !== timestop.initialFee) return true

            // New Field clicked
            if (timestop.officeChangedFee && timestop.initialState !== 'officeChangedFee') return true
            if (timestop.withAttest && timestop.initialState !== 'withAttest') return true
            if (timestop.initialState === 'withAttest' && !timestop.withAttest) return true
            if (timestop.initialState === 'withoutAttest' && timestop.withAttest) return true
            return false
        },
        checkOfficeChangedFeeEntered(timestop) {
            console.log('uwu')
            if (timestop.officeChangedFee && timestop.officeChangedFeeAmount)
                this.$toast.error('Setze eine Gebühr fest')
        },
        async saveToTAC(timestop, state) {
            if (state === 'rejected' && !timestop.officeNote)
                return this.$toast.error(
                    'Bitte schreibe dem Kunden eine Rückmeldung bevor du den Timestop ablehnst / zurück gibst'
                )

            timestop.officeChangedFeeAmount = Number(timestop.officeChangedFeeAmount)
            let confirmSpan = 'Der Timestop wird auf ' + this.timestopStateMap[state] + ' gesetzt.'

            let confirmFee = 0
            if (timestop.withAttest) {
                confirmFee = timestop.fee.withAttest
            } else if (timestop.officeChangedFee) {
                confirmFee = timestop.officeChangedFeeAmount
            } else {
                confirmFee = timestop.fee.withoutAttest
            }
            if (state === 'onHold') {
                confirmSpan =
                    'Der Timestop wird akzeptiert und geht mit einer Zahlungsaufforderung zurück an den Kunden. \nEr wird noch nicht in TAC eingetragen \nDem Kunden werden ' +
                    confirmFee +
                    ' CHF in Rechnung gestellt.'
            }

            if (state === 'accepted') {
                confirmSpan =
                    'Der Timestop des Kunden wir nun im TAC eingetragen und automatisch die dazugehörige Rechnung im TAC erstellt..'
            }
            if (state === 'payed') {
                confirmSpan = 'Der Timestop wird auf bezahlt gesetzt und ans Büro weitergeleitet'
            }
            if (confirm(confirmSpan)) {
                timestop.status = state

                timestop.tacData.IdlePeriodFee.value = confirmFee

                try {
                    if (state === 'accepted') {
                        let tacTimestop = await api.createTACTimestop(timestop)
                    }
                    let upated = await api.updateTimeStopFormEntry(timestop)
                    this.fetchTimestops()
                } catch (e) {
                    this.$toast.error(e.response.data)
                }
            }
            //let test = await api.createTACTimestop(timestop.tacData)
        },
        async downloadPDF(id) {
            try {
                await api.fetchTimestopPDF(id)
            } catch (e) {}
            console.log(id)
        },
        async fetchTimestops() {
            try {
                this.tacReasons = await api.getTACReasons(1)
                let timestops = await api.fetchTimestopsAdmin(this.selectedStatus)
                this.timestops = await Promise.all(
                    timestops.map(async (timestop) => {
                        if (!timestop.changeLog) timestop.changeLog = []
                        timestop.initialFee = JSON.parse(JSON.stringify(timestop.tacData.IdlePeriodFee.value))
                        if (timestop.officeChangedFee) {
                            timestop.officeChangedFeeAmount = timestop.tacData.IdlePeriodFee.value || 0
                            timestop.initialState = 'officeChangedFee'
                        } else {
                            timestop.initialState = timestop.withAttest ? 'withAttest' : 'withoutAttest'
                        }
                        timestop.changeLog.push(
                            JSON.parse(
                                JSON.stringify({
                                    state: timestop.initialState,
                                    fee: timestop.initialFee,
                                    note: timestop.officeNote,
                                })
                            )
                        )
                        return timestop
                    })
                )
                this.selectedStudios = [...new Set(this.timestops.map((r) => r.studio.name))]
                this.loading = false
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim laden')
            }
        },
    },
    computed: {
        timestopsFiltered() {
            if (this.timestops.length > 0) {
                return this.timestops.filter((e) => this.selectedStudios.includes(e.studio.name))
            } else {
                return []
            }
        },
        availableStudios() {
            let names = []
            if (this.timestops.length > 0) {
                names = this.timestops.map((r) => r.studio.name).sort()
            }

            return [...new Set(names)]
        },
    },
}
</script>

<style lang="scss" scoped>
.studio-name {
    font-weight: bold;
}

.time {
    font-size: 12px;
    color: #999;
}

.contract-name {
    font-style: italic;
}
</style>